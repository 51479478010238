@import '@creativebulma/bulma-tooltip';
@import "assets/bloggersans/bloggersans.css";

body, button, input, optgroup, select, textarea {
  font-family: 'Blogger Sans', BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body {
  font-size: 1.1em;
}

.label {
  font-size: 1.25rem;
}

.show {
  visibility: visible;
  position: static;
}

.hide {
  visibility: hidden;
  position: absolute;
}

.no-style {
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
}

.divider.horizontal {
  height: 1px;
  width: 100%;
  background: lightgray;
}

.divider.vertical {
  height: auto;
  width: 1px;
  background: lightgray;
}

.control.input-with-error.vertical {
  width: 100%;
}

.control.input-with-error input {
  width: auto;
}

.control.input-with-error.horizontal input {
  width: auto;
}

.control.input-with-error label.error {
  color: red;
  font-size: 12px;
  visibility: hidden;
}

.control.input-with-error.horizontal label.error {
  margin-left: 20px;
  line-height: 36px;
}

.control.input-with-error input.ng-invalid + label.error {
  visibility: visible;
}

span[data-tooltip] {
  border-bottom: none !important;
}

.modal-content {
  width: 800px!important;
}

.footer {
  padding: 20px;
}
